<template>
	<div class="my-account">
		<div class="header-menu va-row" id="header-menu">
			<div class="container-dhh va-row">
				<div class="close">
					<router-link :to="{ name: 'parents-home' }">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="36"
							height="36"
							viewBox="0 0 36 36"
						>
							<path
								fill="#000"
								fill-rule="evenodd"
								stroke="#000"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="1.6"
								d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
							/>
						</svg>
					</router-link>
				</div>

				<div class="flex xs12 no-padding text-center">
					<h1>Visualizar Livro</h1>
				</div>
				<!-- <hr> -->
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="flex xs12 no-padding text-center">
			<!-- <PreviewWithTools 
          v-if="isLoaded"
          :current_name="subscription.characterName"
          :uuid="product.builderCharacterId" 
          :slug="product.characterSlug" 

          :pages="pageNum"
          :age="product.tagName.substring(0, 1)"

          :character_id="product.characterId" 
          :product_id="parseInt(product.builderProductId)" 
          :gender="gender" 

          :have_tools="haveTools"
          :have_name="false"
          :have_shirt_number="false"
          :have_layout="false"
          :have_birth_date="false"
          :have_pet="false"
        /> -->

			<PreviewFull
				v-if="isLoaded"
				:current_name="parentCredential.students[parentCredential.name]"
				:uuid="parentCredential.students[parentCredential.selectedStudent].uuid"
				:slug="
					parentCredential.students[parentCredential.selectedStudent].product
						.sku
				"
				:pages="
					parentCredential.students[parentCredential.selectedStudent].product
						.pages
				"
				:age="age"
				:character_id="
					parentCredential.students[parentCredential.selectedStudent].product
						.character_id
				"
				:product_id="
					parentCredential.students[parentCredential.selectedStudent].product
						.builder_id
				"
				gender="M"
				:have_name="false"
				:have_shirt_number="false"
				:have_layout="false"
				:have_birth_date="false"
				:have_pet="false"
			/>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import turn from "services/turn.min";
	const axios = require("axios");

	$.turn = turn;

	export default {
		name: "preview",
		computed: {
			...mapGetters({
				parentCredential: "parentCredential",
				// ...
			}),
		},
		components: {},
		props: {
			/**
			 * Indica se o botão esta desabilitado ou não
			 */
			product: {
				type: Object,
				required: false,
			},
			// subscription: {
			//   type: Object,
			//   required: false,
			// },
		},

		data() {
			return {
				isLoaded: false,
				pageNum: 36,
				age: 6,
				gender: "menino",
				haveTools: true,
			};
		},

		mounted() {
			const self = this;
			// if(this.product.tagName){
			//   this.age = parseInt(this.product.tagName.substring(0, 1))
			// }

			axios
				.get(
					"https://builder.dentrodahistoria.com.br/api/products/info/" +
						self.parentCredential.students[
							self.parentCredential.selectedStudent
						].product.builder_id
				)
				.then(function (response) {
					self.pageNum = response.data.product.pages;
					self.isLoaded = true;
				});

			// if(self.subscription.genderTag === undefined){
			//   self.haveTools = false
			// }

			//self.gender = subscription.genderTag.slug ? subscription.genderTag.slug : 'menino'
		},

		updated() {
			const self = this;
		},

		methods: {},
	};
</script>
<style lang="scss" scoped>
	.my-account {
		position: absolute;
		top: 0px;
		background-color: #f8f7fc;
		z-index: 3;
		width: 100%;
		padding-bottom: 30px;
		min-height: 100%;
		overflow: hidden;
		// height: 100%;
		z-index: 11;
	}

	.header-menu {
		padding-bottom: 8px !important;
		border-bottom: 1px solid #dfdae4;
		margin-bottom: 20px !important;
		overflow: hidden;
		z-index: 9;

		@media (max-width: 990px) {
			position: relative;
			padding-bottom: 15px !important;
			margin-bottom: 40px !important;
			// padding-bottom: 10px !important;
		}

		h1 {
			font-size: 24px;
			margin-bottom: 25px;
			margin-top: 40px;

			@media (max-width: 990px) {
				font-size: 20px;
				margin-top: 20px;
				margin-bottom: 0px;
			}
		}

		.close {
			position: absolute;
			top: 20px;
			left: 5px;

			@media (max-width: 990px) {
				top: 10px;
			}
		}
	}

	.container-dhh {
		@media (max-width: 990px) {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}
</style>
